import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { type ReactNode, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useTitle } from 'react-use';

import {
  type DtoProduct,
  type HubspotProduct,
} from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { Loading } from '../components/Loading';
import {
  AllPricePicker,
  CustomPriceEditor,
  usePriceOptions,
} from '../components/Product/CustomPrice';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { err2s, makeTitle } from '../utils/common';
import { buildReactSelectStyles } from '../utils/react-select';

export async function clientLoader(_action: ClientLoaderFunctionArgs) {
  const resp = await apiService.product.getPublicProducts();

  return { marketedStripeProducts: resp.data.marketed };
}

type FormData = {
  name: string;
  description: string;
  internalProductId: string | null;
  stripePriceId: string | null;
};

export function HubSpotProductEditor(props: {
  product: HubspotProduct | null;
  marketedStripeProducts: DtoProduct[];
  setSidePanel: (panel: ReactNode) => void;
}) {
  const { product, marketedStripeProducts } = props;

  const form = useForm<FormData>({
    defaultValues: {
      name: product?.name ?? '',
      description: product?.description ?? '',
      internalProductId: product?.hs_sku?.split(',')[0] || null,
      stripePriceId: product?.hs_sku?.split(',')[1] || null,
    },
  });
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: {
            height: '40px',
          },
          groupHeading: {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: '#8B9294',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
          },
        },
      }),
    []
  );
  const productId = form.watch('internalProductId');
  const internalProduct = marketedStripeProducts.find(
    (p) => p.id === productId
  );
  const { priceOptions, isLoading, mutate } = usePriceOptions(internalProduct);

  const openAddCustomPriceEditor = () => {
    if (!internalProduct) return;
    props.setSidePanel(
      <CustomPriceEditor
        product={internalProduct}
        defaultAmount={1}
        onCancel={() => props.setSidePanel(null)}
        onSave={async (price) => {
          await mutate();
          form.setValue('stripePriceId', price.id);
          props.setSidePanel(null);
        }}
      />
    );
  };

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = form;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const nagivate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    if (!data.internalProductId || !data.stripePriceId) return;

    try {
      let url: string;
      if (product) {
        const resp = await apiService.hubSpot.updateProduct(
          product.hs_object_id,
          {
            name: data.name,
            description: data.description,
            internalProductId: data.internalProductId,
            stripePriceId: data.stripePriceId,
          }
        );
        url = resp.data.product.hs_url;
      } else {
        const resp = await apiService.hubSpot.createProduct({
          name: data.name,
          description: data.description,
          internalProductId: data.internalProductId,
          stripePriceId: data.stripePriceId,
        });
        url = resp.data.product.hs_url;
      }

      await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-3 text-white text-center'>
            <p className='text-2xl font-medium'>
              Product {product ? 'Updated' : 'Created'}
            </p>
            <p className='mt-4 text-sm'>
              This is the{' '}
              <Link to={url} target='_blank' className='underline text-primary'>
                payment link
              </Link>
            </p>
          </div>
        ),
        confirmBtnLabel: 'Okay',
        confirmOnly: true,
      });
      nagivate('/admin/hubspot/products');
    } catch (error) {
      form.setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
  });

  return (
    <form className='flex flex-col gap-4'>
      <main className='w-1/3 flex flex-col gap-4'>
        <Controller
          name='name'
          control={form.control}
          rules={{ required: 'Please fill the product name' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='w-full flex flex-col gap-1'>
              <label className='font-bold'>Name</label>
              <input
                type='text'
                placeholder='Product Name'
                className={`${
                  error ? 'field-error' : 'field'
                } w-full h-10 mb-0`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
              {error && (
                <div className='text-sms text-red-002'>{error.message}</div>
              )}
            </div>
          )}
        />
        <Controller
          name='description'
          control={form.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='w-full flex flex-col gap-1'>
              <label className='font-bold'>Description</label>
              <input
                type='text'
                placeholder='Product Description'
                className={`${
                  error ? 'field-error' : 'field'
                } w-full h-10 mb-0`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
              {error && (
                <div className='text-sms text-red-002'>{error.message}</div>
              )}
            </div>
          )}
        />
        <Controller
          name='internalProductId'
          control={form.control}
          rules={{ required: 'Please select the Stripe Product' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='w-full flex flex-col gap-1'>
              <label className='font-bold'>Stripe Product</label>
              <Select<DtoProduct>
                options={marketedStripeProducts}
                onChange={(o) => onChange(o?.id)}
                value={
                  marketedStripeProducts.find((p) => p.id === value) ?? null
                }
                classNamePrefix='select-box-v2'
                className='w-full h-full'
                styles={styles}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
              {error && (
                <div className='text-sms text-red-002'>{error.message}</div>
              )}
            </div>
          )}
        />
        <Controller
          name='stripePriceId'
          control={form.control}
          rules={{ required: 'Please select the Stripe Price' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <div className='w-full flex flex-col gap-1'>
                <div className='flex items-center justify-between'>
                  <label className='font-bold'>Stripe Price</label>
                  {productId && !isLoading && (
                    <button
                      type='button'
                      className='text-sms text-left text-primary underline'
                      onClick={openAddCustomPriceEditor}
                    >
                      Add new custom price
                    </button>
                  )}
                </div>
                {isLoading ? (
                  <Loading text='' />
                ) : (
                  <AllPricePicker
                    priceOptions={priceOptions}
                    disabled={!productId}
                    value={
                      priceOptions
                        .flatMap((o) => o.options)
                        .find((o) => o.id === value) ?? null
                    }
                    onChange={(o) => onChange(o?.id)}
                  />
                )}
                {error && (
                  <div className='text-sms text-red-002'>{error.message}</div>
                )}
              </div>
            );
          }}
        />
      </main>
      <footer className='flex items-center gap-4 mt-4'>
        <button
          type='button'
          className='btn-secondary w-30 h-10'
          disabled={!isDirty}
          onClick={() => reset()}
        >
          Reset
        </button>
        <button
          type='button'
          className='btn-primary w-30 h-10 flex items-center justify-center gap-1'
          onClick={onSubmit}
          disabled={!isDirty || isSubmitting}
        >
          {isSubmitting && <Loading text='' />}
          <p>{product ? 'Update' : 'Create'}</p>
        </button>
      </footer>
    </form>
  );
}

export function Component() {
  const { marketedStripeProducts } = useLoaderData<typeof clientLoader>();
  useTitle(makeTitle('New HubSpot Product'));

  const [sidePanel, setSidePanel] = useState<ReactNode>(null);

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <div className='w-full relative text-white flex flex-col gap-10'>
        <header className='flex justify-between items-center'>
          <h1 className='font-bold text-3xl flex items-center gap-1'>
            <p>New HubSpot Product</p>
          </h1>
        </header>
        <HubSpotProductEditor
          product={null}
          marketedStripeProducts={marketedStripeProducts}
          setSidePanel={setSidePanel}
        />
        {sidePanel && (
          <div className='fixed inset-0 z-50'>
            <div className='absolute inset-0 bg-lp-black-001' />
            <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
              {sidePanel}
            </div>
          </div>
        )}
      </div>
    </AdminView>
  );
}
